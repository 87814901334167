import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-edf70692"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "project-table",
  style: {
    "margin-top": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $data.tableData,
    "row-key": "id",
    border: "",
    "header-cell-style": {
      'text-align': 'center',
      'color': '#999999',
      'padding': '16px 0',
      'font-size': '16px',
      'background-color': '#F6F6F6'
    },
    "cell-style": {
      'text-align': 'center',
      'padding': '16px 0'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: "班级名称",
      prop: "name"
    }), _createVNode(_component_el_table_column, {
      label: "学校名称",
      prop: "school_name"
    }), _createVNode(_component_el_table_column, {
      label: "年级",
      prop: "grade"
    }), _createVNode(_component_el_table_column, {
      label: "参与人数",
      prop: "num"
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "班级报告"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_button, {
        link: "",
        type: "primary",
        onClick: $event => $options.showEdit(scope.row.id)
      }, {
        default: _withCtx(() => [_createTextVNode("下载")]),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])])])]);
}