import "core-js/modules/es.array.push.js";
import { getClassReportList } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      operator_server_active_id: '',
      dialogFormVisible: false
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.operator_server_active_id = data.id;
    this.getList();
  },
  methods: {
    search() {
      this.getList();
    },
    getList() {
      getClassReportList({
        operator_server_active_id: this.operator_server_active_id
      }).then(res => {
        this.tableData = res.data;
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/admin/report/classReportList",
        query: {
          id: id
        }
      });
    }
  }
};